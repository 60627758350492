import { Routes } from '@angular/router';

import { NoContentComponent } from './components/no-content/no-content.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ContactComponent } from './components/contact/contact.component';
import { ServicesComponent } from './components/services/services.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

import { NoContentImageResolver } from './resolvers/no-content-image-resolver';
import { WelcomeImageResolver } from './resolvers/welcome-image-resolver';
import { ServiceImageResolver } from './resolvers/service-image-resolver';
import { ContactImageResolver } from './resolvers/contact-image-resolver';

export const ROUTES: Routes = [
  { path: 'articles', component: ArticlesComponent },
  { path: 'contact', component: ContactComponent, resolve: { contactImage: ContactImageResolver } },
  { path: 'services', component: ServicesComponent, resolve: { serviceImage: ServiceImageResolver } },
  { path: '', component: WelcomeComponent, resolve: { welcomeImage: WelcomeImageResolver } },
  { path: '**',      component: NoContentComponent, resolve: { background: NoContentImageResolver } }
];
