import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private httpClient: HttpClient) { }

  sendEmail(user: User): Observable<any> {
    return this.httpClient.post('https://mail.hmc-dn.ru/sender1',
      {
        name: user.displayName,
        phone: user.phoneNumber
      },
      {
        responseType: 'text'
      });
  }
}
