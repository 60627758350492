import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from 'environments/environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS
];

import { ENV_FIREBASE_CONFIG } from './firebase.config';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { SharedModule } from './modules/shared/shared.module';

import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';

import { widgetConfig } from './widget.config';

const cloudinaryConfig: CloudinaryConfiguration = {
  cloud_name: widgetConfig.cloud_name,
  upload_preset: widgetConfig.upload_preset,
  api_key: widgetConfig.api_key,
  api_secret: widgetConfig.api_secret
};

import { NoContentComponent } from './components/no-content/no-content.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ContactComponent } from './components/contact/contact.component';
import { ServicesComponent } from './components/services/services.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

import { AuthenticationService} from './services/authentication.service';
import { ArticleService } from './services/article.service';
import { DialogService } from './services/dialog.service';
import { ServiceService } from './services/service.service';
import { MailService } from './services/mail.service';

import { CallbackDialogComponent } from './components/callback-dialog/callback-dialog.component';
import { CallbackFormComponent } from './components/callback-form/callback-form.component';
import { ArticleDialogComponent } from './components/article-dialog/article-dialog.component';

import { PricePipe } from './pipes/price.pipe';

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    NoContentComponent,
    ArticlesComponent,
    ContactComponent,
    ServicesComponent,
    WelcomeComponent,
    CallbackDialogComponent,
    CallbackFormComponent,
    ArticleDialogComponent,
    PricePipe
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(ENV_FIREBASE_CONFIG),
    AngularFirestoreModule,
    FlexLayoutModule,
    SharedModule,
    CloudinaryModule.forRoot({ Cloudinary }, cloudinaryConfig),
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules
    })
  ],
  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    environment.ENV_PROVIDERS,
    APP_PROVIDERS,
    AuthenticationService,
    ArticleService,
    DialogService,
    ServiceService,
    MailService
  ],
  entryComponents: [
    CallbackDialogComponent,
    ArticleDialogComponent,
  ]
})
export class AppModule {}
