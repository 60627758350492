import { Component, OnInit } from '@angular/core';

import { SafeStyle } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Service } from '../../interfaces/service';

import { ServiceService } from '../../services/service.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  serviceImage: SafeStyle;

  services: Service[];

  constructor(public activatedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              private serviceService: ServiceService) { }

  ngOnInit() {
    this.serviceImage = this.activatedRoute.snapshot.data.serviceImage;

    this.serviceService
      .getServices()
      .pipe(untilDestroyed(this))
      .subscribe(
        (value) => {
          this.services = value.filter((v) => v.visible);
        },
        (error) => {
          this.snackBar.open(error.error, 'Помилка');
        });
  }
}
