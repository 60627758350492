import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { User } from '../../interfaces/user';

import { MailService } from '../../services/mail.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-callback-dialog',
  templateUrl: './callback-dialog.component.html',
  styleUrls: ['./callback-dialog.component.scss']
})
export class CallbackDialogComponent implements OnInit {

  callbackForm: FormGroup;

  private user: User = {} as User;

  constructor(public dialogRef: MatDialogRef<CallbackDialogComponent>,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private mailService: MailService) {

    this.callbackForm = formBuilder.group({
      name: [null, [Validators.required]],
      phone: [null, [Validators.required]]
    });
  }

  ngOnInit() {
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSend() {
    if (this.callbackForm.valid) {
      this.user.displayName = this.callbackForm.value.name;
      this.user.phoneNumber = this.callbackForm.value.phone;
      this.mailService
        .sendEmail(this.user)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.snackBar.open('Вашу заявку успішно відправлено.', 'Успішно');
            this.onClose();
          },
          () => {
            this.snackBar.open('Сервіс недоступний, спробуйте пізніше.', 'Помилка');
          },
          () => {
            this.user = {} as User;
          });
    }
  }
}
