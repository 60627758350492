import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let message: string;
    switch (true) {
      case value === 0:
        message = 'договірна вартість';
        break;
      case value < 0:
        message = `від $ ${Math.abs(value)}`;
        break;
      case value > 0:
        message = `від ${value} грн.`;
        break;
      default:
        message = value;
    }
    return message;
  }

}
