import { Component, OnInit } from '@angular/core';

import { SafeStyle } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactImage: SafeStyle;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.contactImage = this.activatedRoute.snapshot.data.contactImage;
  }

}
