<mat-toolbar>
  <mat-toolbar-row>
    <img [alt]="text" [src]="logo">
    <div fxLayout="column"
         fxLayoutAlign="space-evenly start"
         *ngIf="!authService.isLoggedIn"
         class="app-contacts">
      <a href="mailto:office@alterust.com.ua">
        <mat-icon>email</mat-icon>
        <abbr title="пошта"></abbr>
        office@alterust.com.ua
      </a>
      <a href="tel:+380 98 777 87 21">
        <mat-icon>phone</mat-icon>
        <abbr title="телефон"></abbr>
        +380 98 777 87 21
      </a>
      <a href="javascript:void(0)"
         (click)="onCallback()">
        <mat-icon>edit</mat-icon>
        <abbr title="зворотний дзвінок"></abbr>
        замовити зворотній дзвінок
      </a>
    </div>
    <div *ngIf="authService.isLoggedIn"
         class="app-settings">
      <a [href]="url">
        <mat-icon>settings</mat-icon>
        <abbr title="управління"></abbr>
        управління
      </a>
    </div>
    <span fxFlex></span>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      *ngIf="mediaQueryList.matches"
      color="primary">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <a mat-menu-item
         routerLink=""
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}"
         color="primary">
        Компанія
      </a>
      <a mat-menu-item
         routerLink="services"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}"
         color="primary">
        Послуги та ціни
      </a>
      <!--<a mat-menu-item
         routerLink="articles"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}"
         color="primary">
        Блог
      </a>-->
      <a mat-menu-item
         routerLink="contact"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}"
         color="primary">
        Контакти
      </a>
    </mat-menu>
    <a mat-button
       routerLink=""
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Компанія
    </a>
    <a mat-button
       routerLink="services"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Послуги та ціни
    </a>
   <!-- <a mat-button
       routerLink="articles"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Блог
    </a>-->
    <a mat-button
       routerLink="contact"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Контакти
    </a>
  </mat-toolbar-row>
</mat-toolbar>
<main [@routeAnimation]="outlet.isActivated ? outlet.activatedRoute : null">
  <router-outlet #outlet="outlet"></router-outlet>
</main>
