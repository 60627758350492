import { Component } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { User } from '../../interfaces/user';

import { MailService } from '../../services/mail.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-callback-form',
  templateUrl: './callback-form.component.html',
  styleUrls: ['./callback-form.component.scss']
})
export class CallbackFormComponent {

  callbackForm: FormGroup;

  private user: User = {} as User;

  constructor(private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private mailService: MailService) {

    this.callbackForm = formBuilder.group({
      name: [null, [Validators.required]],
      phone: [null, [Validators.required]]
    });
  }

  onSend(form) {
    if (this.callbackForm.valid) {
      this.user.displayName = this.callbackForm.value.name;
      this.user.phoneNumber = this.callbackForm.value.phone;
      this.mailService
        .sendEmail(this.user)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            form.resetForm({ name: null, phone: null });
            this.snackBar.open('Ваша заявка успешно отправлена.', 'Успешно');
          },
          () => {
            this.snackBar.open('Сервис недоступен, попробуйте позже.', 'Ошибка');
          },
          () => {
            this.user = {} as User;
          });
    }
  }

  onEnter($event) {
    $event.preventDefault();
  }
}
