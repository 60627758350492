import { Component, OnInit } from '@angular/core';

import { SafeStyle } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  text = '«АльтерЮст»';

  welcomeImage: SafeStyle;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.welcomeImage = this.activatedRoute.snapshot.data.welcomeImage;
  }
}
