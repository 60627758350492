import { Injectable } from '@angular/core';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { Article } from '../interfaces/article';

import { CallbackDialogComponent } from '../components/callback-dialog/callback-dialog.component';
import { ArticleDialogComponent } from '../components/article-dialog/article-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  openCallbackDialog(): void {
    this.dialog.open(CallbackDialogComponent, { autoFocus: false, width: '100vh' });
  }

  openArticleDialog(article: Article): Observable<any> {

    let ref: MatDialogRef<ArticleDialogComponent>;

    ref = this.dialog.open(ArticleDialogComponent);

    ref.componentInstance.title = article.title;
    ref.componentInstance.content = article.content;

    return ref.afterClosed();
  }
}
