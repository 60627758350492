import { Component, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Article } from '../../interfaces/article';

import { ArticleService } from '../../services/article.service';
import { DialogService } from '../../services/dialog.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {

  articles: Article[];

  constructor(private snackBar: MatSnackBar,
              private articleService: ArticleService,
              private dialogService: DialogService) { }

  ngOnInit() {
    this.articleService
      .getArticles()
      .pipe(untilDestroyed(this))
      .subscribe(
        (value) => {
          this.articles = value.filter((v) => v.visible);
        },
        (error) => {
          this.snackBar.open(error.error, 'Помилка');
        });
  }

  onShowArticle(article: Article) {
    this.dialogService.openArticleDialog(article);
  }

}
