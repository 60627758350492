<section [style.backgroundImage]="welcomeImage"
         fxLayout="column"
         fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <header fxLayout="column" fxLayoutAlign="center center" class="header mat-card">
        <h2>Компанія заснована досвідченими юристами у сфері корпоративного права</h2>
        <h2>Більше 10 років досвіду та сотні задоволених клієнтів</h2>
      </header>
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlexOffset="2%">
        <div fxLayout="column" fxFlex="60%" fxFlex.lt-lg="50%" fxFlex.lt-md="40%" fxFlex.lt-sm="0%"></div>
        <article fxLayout="column" fxFlex="40%" fxFlex.lt-lg="50%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%">
          <mat-card>
            <mat-card-header>
            </mat-card-header>
            <mat-card-content>
              <img [alt]="text" src="assets/images/logo-112x112.png">
            </mat-card-content>
            <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">
              <button mat-raised-button
                      [routerLink]="['/services']"
                      class="app-button">
                наші послуги
              </button>
            </mat-card-actions>
          </mat-card>
        </article>
      </div>
      <div fxFlex></div>
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlexOffset="2%">
        <div fxLayout="column" fxFlex="30%" fxFlex.lt-lg="25%" fxFlex.lt-md="0%" fxFlex.lt-sm="0%"></div>
        <article fxLayout="column" fxFlex="40%" fxFlex.lt-lg="50%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%">
          <app-callback-form></app-callback-form>
        </article>
        <div fxLayout="column" fxFlex="30%" fxFlex.lt-lg="25%" fxFlex.lt-md="0%" fxFlex.lt-sm="0%"></div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
</section>
