<section fxLayout="column"
         fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <header fxLayout="row" fxLayoutAlign="center center" class="header mat-card">
        <h2>Блог</h2>
      </header>
      <article>
        <table class="app-list">
          <tr *ngFor="let article of articles">
            <td>
              <a href="javascript:void(0)" (click)="onShowArticle(article)">
                {{ article.title }}
              </a>
            </td>
          </tr>
        </table>
      </article>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
</section>
