<form [formGroup]="callbackForm" #form="ngForm" (submit)="onSend(form)" novalidate>
  <mat-card>
    <mat-card-header>
      <mat-card-title>ХОЧЕТЕ ОТРИМАТИ КОНСУЛЬТАЦІЮ?</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayoutAlign="space-between stretch">
      <mat-form-field>
        <input matInput
               (keydown.enter)="onEnter($event)"
               placeholder="* Ваше ім'я"
               formControlName="name"
               minlength="0"
               maxlength="96">
        <mat-error *ngIf="callbackForm.controls.name.invalid">
          Будь ласка, введіть ім'я.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput
               (keydown.enter)="onEnter($event)"
               placeholder="* Ваш телефон"
               formControlName="phone"
               minlength="0"
               maxlength="96">
        <mat-error *ngIf="callbackForm.controls.phone.invalid">
          Будь ласка, введіть номер телефону.
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">
      <span class="read-more"></span>
      <button mat-raised-button class="app-button">
        замовити зворотній дзвінок
      </button>
    </mat-card-actions>
  </mat-card>
</form>
