import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-article-dialog',
  templateUrl: './article-dialog.component.html',
  styleUrls: ['./article-dialog.component.scss']
})
export class ArticleDialogComponent {

  title: string;
  content: string;

  constructor(public articleDialogRef: MatDialogRef<ArticleDialogComponent>) { }

}
