<section [style.backgroundImage]="serviceImage"
         fxLayout="column"
         fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <header fxLayout="row" fxLayoutAlign="center center" class="header mat-card">
        <h2>Послуги та ціни</h2>
      </header>
      <article class="services">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let service of services"
                               [expanded]="activatedRoute.snapshot.queryParams.id === service.id">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ service.title }}
              </mat-panel-title>
              <mat-panel-description>
                {{ service.price | price }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <cl-image
              *ngIf="service.publicId"
              public-id="{{ service.publicId }}"
              dpr="auto"
              responsive
              width="200"
              crop="scale"
              class="thumbnail inline">
            </cl-image>
            <div class="content" [innerHTML]="service.content"></div>
          </mat-expansion-panel>
        </mat-accordion>
      </article>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
</section>
