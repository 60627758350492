<section [style.backgroundImage]="contactImage"
         fxLayout="column"
         fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <header fxLayout="row" fxLayoutAlign="center center" class="header mat-card">
        <h2>Контакти</h2>
      </header>
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlexOffset="2%">
        <div fxLayout="column" fxFlex="60%" fxFlex.lt-lg="50%" fxFlex.lt-md="0%" fxFlex.lt-sm="0%"></div>
        <article fxLayout="column" fxFlex="40%" fxFlex.lt-lg="50%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%">
          <mat-card class="contact">
            <mat-card-content>
              <div fxLayout="row"
                   fxLayoutAlign="space-evenly center"
                   fxLayout.lt-sm="column"
                   class="container">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                  <img alt="скайп" src="../../../assets/images/phone.svg">
                  <a href="tel:+380 98 777 87 21">+380 98 777 87 21</a>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-between center">
                  <img alt="телефон" src="../../../assets/images/skype.svg">
                  <a href="tel:alterust27">alterust27</a>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-between center">
                  <img alt="почта" src="../../../assets/images/email.svg">
                  <a href="mailto:office@alterust.com.ua">office@alterust.com.ua</a>
                </div>
              </div>
              <br>
              <div fxLayout="column"
                   fxLayoutAlign="space-evenly start"
                   class="container">
                <p>Зв'яжіться з нами, і ми обов'язково призначимо зустріч у зручний для Вас час.</p>
              </div>
            </mat-card-content>
          </mat-card>
        </article>
      </div>
      <div fxFlex></div>
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlexOffset="2%">
        <div fxLayout="column" fxFlex="30%" fxFlex.lt-lg="25%" fxFlex.lt-md="0%" fxFlex.lt-sm="0%"></div>
        <article fxLayout="column" fxFlex="40%" fxFlex.lt-lg="50%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%">
          <app-callback-form></app-callback-form>
        </article>
        <div fxLayout="column" fxFlex="30%" fxFlex.lt-lg="25%" fxFlex.lt-md="0%" fxFlex.lt-sm="0%"></div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
</section>
