import { Component, OnInit } from '@angular/core';

import { SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { APP_URL } from '../../app.config';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent implements OnInit {

  url = APP_URL;

  backgroundImage: SafeStyle;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.backgroundImage = this.route.snapshot.data['background'];
  }
}
