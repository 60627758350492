<h1 mat-dialog-title>ОФОРМЛЕННЯ ЗАЯВКИ</h1>
<form [formGroup]="callbackForm" (ngSubmit)="onSend()" novalidate>
  <div mat-dialog-content class="app-card">
    <mat-form-field>
      <input matInput
             placeholder="* Ваше ім'я"
             formControlName="name"
             minlength="0"
             maxlength="96">
      <mat-error *ngIf="callbackForm.controls.name.invalid">
        Будь ласка, введіть ім'я.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput
             placeholder="* Ваш телефон"
             formControlName="phone"
             minlength="0"
             maxlength="96">
      <mat-error *ngIf="callbackForm.controls.phone.invalid">
        Будь ласка, введіть номер телефону.
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <a href="javascript:void(0)"
       (click)="onClose()"
       class="read-more">
      <abbr title="скасування"></abbr>
      Скасування
    </a>
    <button mat-raised-button class="app-button">
      відправити заявку
    </button>
  </div>
</form>
