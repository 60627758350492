<section [style.backgroundImage]="backgroundImage"
         fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h1>Господа присяжні,</h1>
        <p>сторінку не знайдено, але ви можете ознайомитись з послугами компанії на <a [href]="url">головній</a> сторінці.
        </p>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
</section>
